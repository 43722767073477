import React, { useEffect, useState } from "react";

import { Grid, Tab, Tabs } from "@mui/material";
import { useIntl } from "gatsby-plugin-intl";

import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import ResultSnackbars from "components/backoffice/common/ResultSnackbars";
import CvcTabPanel from "ui/CvcTabPanel";
import { CvcAutocompleteSelect } from "ui/RhfAutocompleteSelect";

import listOfValueService from "services/api/listOfValueService";
import { handleError } from "utils/error/error-utils";

import AddReferenceDataTab from "components/backoffice/repositories/tabs/AddReferenceDataTab";
import DeleteReferenceDataTab from "components/backoffice/repositories/tabs/DeleteReferenceDataTab";
import StandardizeReferenceDataTab from "components/backoffice/repositories/tabs/StandardizeReferenceDataTab";
import ExportReferenceDataTab from "components/backoffice/repositories/tabs/ExportReferenceDataTab";
import { getConnectedUser } from "services/api/requests";


const RepositoriesPage = () => {
    const intl = useIntl();   
    const [activeTab, setActiveTab] = useState("");

    const [loadingTables, setLoadingTables] = useState(false);
    const [disabledTables, setDisabledTables] = useState(false);
    const [referenceTableOptions, setReferenceTableOptions] = useState([]);
    const [selectedReferenceTable, setSelectedReferenceTable] = useState(null);
    const [connectedUser, setConnectedUser] = useState(null);
    const [resultMessage, setResultMessage] = useState("");
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
  
    const defaultTabs  = [    
        { value: "standardisation", title: "backoffice.repositories.tabs.cardHeader.standardisation" },
        { value: "creation", title: "backoffice.repositories.tabs.cardHeader.creation" },
        { value: "deletion", title: "backoffice.repositories.tabs.cardHeader.deletion" },
        { value: "export", title: "backoffice.repositories.tabs.cardHeader.export" }
    ]; 

    const universityUnitTabs  = [    
        { value: "standardisation", title: "backoffice.repositories.tabs.cardHeader.standardisation" },
        { value: "creation", title: "backoffice.repositories.tabs.cardHeader.creation" },
        { value: "deletion", title: "backoffice.repositories.tabs.cardHeader.deletion" },
        { value: "export", title: "backoffice.repositories.tabs.cardHeader.export" }
    ]; 

    const universityDomainTabs  = [    
        { value: "creation", title: "backoffice.repositories.tabs.cardHeader.creation" },
        { value: "deletion", title: "backoffice.repositories.tabs.cardHeader.deletion" },
        //{ value: "export", title: "backoffice.repositories.tabs.cardHeader.export" }
    ]; 

    const [tabs, setTabs] = useState([]);

    const sortByLabel = (item1, item2) => {
        if (item1.label < item2.label) {
            return -1;
        }
        if (item1.label > item2.label) {
            return 1;
        }
        return 0;
    }   
        
    const buildReferenceTablesOptions =  (repositories) => {                 
        let tables = repositories.map(repository => 
            { return { value: repository, label: intl.formatMessage({ id: `backoffice.repositories.reference.tables.${repository}`})}
        });    
        return tables.sort(sortByLabel);  
    };

     useEffect(() => {
        let mounted = true;
        async function fetchConnectedUser() {
            setLoadingTables(true);
            try {
                const user = await getConnectedUser();
                console.log("user", user);
                if(mounted) {
                    setConnectedUser(user);
                }
            } catch (error) {
                handleError(intl, error, "error fetching user", showErrorMessage);              
            } finally {
                if(mounted) setLoadingTables(false);
            }
        }        
        if(!loadingTables) fetchConnectedUser();
        return () => {
            mounted = false;
        }
    }, []);

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            setLoadingTables(true);
            try {
                const result =await listOfValueService.findReferenceTables();
                if(mounted){                    
                    setReferenceTableOptions(buildReferenceTablesOptions(result.data));                                                      
                }                                
            } catch (error) {
                handleError(intl, error, "error fetching references tables ", showErrorMessage);              
            } finally {
                if(mounted) setLoadingTables(false);
            }
        };
        if(!loadingTables) fetchData();        
        return () => { mounted=false; };
    }, []);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);        
    };

    const handleReferenceTableChange = (selectedReferenceTableItem) => {
        if(selectedReferenceTableItem){ 
            setSelectedReferenceTable(selectedReferenceTableItem);           
            if(selectedReferenceTableItem.value == 'UNIVERSITY_DOMAIN'){              
                setTabs(universityDomainTabs);                      
                setActiveTab("creation");                                                
            } else if(selectedReferenceTableItem.value == 'UNIVERSITY_UNIT'){  
                setTabs(universityUnitTabs);  
                setActiveTab("standardisation");                                
            } else {
                setTabs(defaultTabs);
                if(activeTab == "") {
                    setActiveTab("standardisation");
                }                
            }                                          
        } else {
            setActiveTab("");
        }        
    }

    const showErrorMessage = (errorMessage) => {
        setResultMessage(errorMessage);
        setOpenError(true);
    }

    const showSuccessMessage = (successMessage) => {
        setResultMessage(successMessage);
        setOpenSuccess(true);
    }

    return (<>
        <LayoutBo>

            <ResultSnackbars
                messageStr={resultMessage}
                openError={openError}
                setOpenError={setOpenError}
                openSuccess={openSuccess}                
                setOpenSuccess={setOpenSuccess}                
            />  
            
            <Grid container sx={{ height: '100%', bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider' }}>
                
                <Grid item xs={12} justifyItems={"center"} marginY={'20px'}>
                    <CvcAutocompleteSelect 
                        id="select-reference-table"
                        label={intl.formatMessage({id: "backoffice.repositories.selectReferenceTable.label"})}
                        value={selectedReferenceTable}
                        options={referenceTableOptions}
                        loading={loadingTables}
                        disabled={disabledTables}
                        onOptionChange={handleReferenceTableChange}
                        sx={{ width: 300 }} 
                    />
                </Grid>

                <Grid item xs={12}>
                    { (selectedReferenceTable && tabs) && 
                        <Tabs 
                            value={activeTab} 
                            onChange={handleTabChange} 
                            aria-label="user edit" 
                            variant="scrollable"
                            sx={{ height: '100%' }}
                        >
                            { tabs.map((tab, index) => {
                                    return (
                                        <Tab 
                                            key={index} 
                                            value={tab.value} 
                                            label={intl.formatMessage({id: tab.title})}  
                                        />
                                    );                               
                                }
                            )}                        
                        </Tabs>
                    }
                </Grid>

            </Grid>

            { selectedReferenceTable && 
            <>                
                <CvcTabPanel id="standardisation" index="standardisation" value={activeTab} ariaLabel="standardisation">
                    <StandardizeReferenceDataTab 
                        userConnected={connectedUser}
                        selectedReferenceTable= {selectedReferenceTable} 
                        setDisabledTables={setDisabledTables}
                        showErrorMessage={showErrorMessage} 
                        showSuccessMessage={showSuccessMessage} 
                    />
                </CvcTabPanel>            
                <CvcTabPanel id="creation" index="creation" value={activeTab} ariaLabel="creation">
                    <AddReferenceDataTab 
                        userConnected={connectedUser}
                        selectedReferenceTable= {selectedReferenceTable} 
                        setDisabledTables={setDisabledTables}
                        showErrorMessage={showErrorMessage} 
                        showSuccessMessage={showSuccessMessage}
                    />
                </CvcTabPanel>
                <CvcTabPanel id="deletion" index="deletion" value={activeTab} ariaLabel="deletion">
                    <DeleteReferenceDataTab 
                        userConnected={connectedUser}
                        selectedReferenceTable= {selectedReferenceTable} 
                        showErrorMessage={showErrorMessage} 
                        showSuccessMessage={showSuccessMessage}
                    />
                </CvcTabPanel>
                <CvcTabPanel id="export" index="export" value={activeTab} ariaLabel="export">
                    <ExportReferenceDataTab 
                        userConnected={connectedUser}
                        selectedReferenceTable= {selectedReferenceTable} 
                        showErrorMessage={showErrorMessage} 
                        showSuccessMessage={showSuccessMessage}
                    />
                </CvcTabPanel>
            </>
            }
                    
        </LayoutBo>     
    </>);
}

export default RepositoriesPage;