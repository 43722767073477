import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button/Button";
import { useIntl } from "gatsby-plugin-intl";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import repositoryService from "services/api/repositoryService";
import { handleError } from "utils/error/error-utils";
import { RhfListOfValuesSelect, RhfTextField } from "components/backoffice/common/RhfFields";
import CvcConfirmActionDialog from "ui/CvcConfirmActionDialog";
import CvcListVirtualized from "ui/CvcListVirtualized";
import CvcLoadingProgress from "ui/CvcLoadingProgress";
import { GROUP } from "utils/constants/back-office-constants";
import { containsRole } from "services/auth";
import { ROLE } from "utils/constants/constants";

const AddReferenceDataTab = ({ 
    userConnected,
    selectedReferenceTable, 
    setDisabledTables, 
    showErrorMessage, 
    showSuccessMessage 
}) => {

    const intl = useIntl();
    const isUniversityDomain = selectedReferenceTable.value == 'UNIVERSITY_DOMAIN';
    const isUniversityUnit = selectedReferenceTable.value == 'UNIVERSITY_UNIT';

    const [loadingDatas, setLoadingDatas] = useState(false);
    const [reloadDatas, setReloadDatas] = useState(true); 
    const [referenceDataList, setReferenceDataList] = useState([]);    
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [disabledAddBtn, setDisabledAddBtn]= useState(true);  
    const [disabledUniversity, setDisabledUniversity]= useState(containsRole([ROLE.academics_manager, ROLE.admin_university]) ? true : false);  
    
    const defaultValue = { 
        valueToAdd: "",
        universityId: containsRole([ROLE.academics_manager, ROLE.admin_university]) ? userConnected.university.universityId : ""
    };

    const validateUniversityDomainToAdd = yup.object().shape({
        valueToAdd: yup.string().trim().required("error.field.required")
                    .matches(/[^/:"]*\.[^/:"]*/, "error.domain.invalid"), 
        universityId: yup.string().trim().required("error.field.required")    
    });

    const validateUniversityUnitToAdd = yup.object().shape({
        valueToAdd: yup.string().trim().required("error.field.required"), 
        universityId: yup.string().trim().required("error.field.required")    
    });

    const validateReferenceDataToAdd = yup.object().shape({
        valueToAdd: yup.string().trim().required("error.field.required")
    });

    const useFormMethods = useForm({
        resolver: yupResolver(isUniversityDomain ? validateUniversityDomainToAdd 
            : isUniversityUnit? validateUniversityUnitToAdd 
            : validateReferenceDataToAdd),
        defaultValues: defaultValue,      
        mode: "onChange",
    });

    useEffect(() => {
        setDisabledTables(false);
    }, []);

    useEffect(() => {
        let mounted = true;
        if(mounted){
            setReloadDatas(true);
        }      
        return () => { mounted=false; };
    }, [selectedReferenceTable]);


    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            setLoadingDatas(true);
            try {
                const findReferenceDataByListCodeDto = {
                    userConnected: containsRole([ROLE.academics_manager, ROLE.admin_university]) ? userConnected : null,
                    listCode: selectedReferenceTable.value
                }  
                const result = await repositoryService.findReferenceDataByListCode(findReferenceDataByListCodeDto);
                if(mounted){                    
                    const referenceDataList = result.data.map(item => { 
                        const universityLabel = item.universityAcronym ? ` - ${item.universityAcronym} `: ' ';
                        item.label = `${item.label}${universityLabel}(${item.count})`;                        
                        return item;
                     });
                    setReferenceDataList(referenceDataList);
                }                                
            } catch (error) {
                handleError(
                    intl, error, 
                    intl.formatMessage({ id: 'backoffice.repositories.fetchValues.error'}), 
                    showErrorMessage
                );
            } finally {                
                setLoadingDatas(false);
                setReloadDatas(false);
            }
        };
        if(reloadDatas && !loadingDatas) fetchData();        
        return () => { mounted=false; };
    }, [reloadDatas]);

    const handleClickAddNewValue = async () => {
        const isValid = await useFormMethods.trigger();          
        if (isValid) {
            setOpenConfirmDialog(true);
        }        
    }

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    }

    const handleConfirmAction = () => {
        setOpenConfirmDialog(false);
        submitForm();
    }

    const handleReloadData = () => {
        setReloadDatas(true);
    }

    const handleValueToAddChange = (e) => {
        if(e && e.target && e.target.value && e.target.value.trim() != ""){
            setDisabledAddBtn(false);
        } else {
            setDisabledAddBtn(true);
        }       
    }

    const submitForm = async () => {
        const isValid = await useFormMethods.trigger();          
        if (isValid) {
            useFormMethods.handleSubmit(handleAddReferencesDatas)();
        }
      };

    const handleAddReferencesDatas = async (form) => {
        try {
            const addReferenceDataDto = {
                referenceValueToAdd: {
                    listCode: selectedReferenceTable.value, 
                    label: form.valueToAdd,                     
                    universityId: form.universityId
                }
            }                                            
            await repositoryService.addReferenceData(addReferenceDataDto);
            showSuccessMessage(intl.formatMessage({ id: 'backoffice.repositories.creation.add.success'}));            
            setReloadDatas(true); 
            useFormMethods.setValue("valueToAdd", "");
            if(isUniversityDomain || isUniversityUnit) useFormMethods.setValue("universityId", "");
            setDisabledAddBtn(true);    
        } catch (error) {
            handleError(
                    intl, error, 
                    intl.formatMessage({ id: 'backoffice.repositories.creation.add.error'}), 
                    showErrorMessage
                );
        }
    }


    return (<>
        { loadingDatas &&  <CvcLoadingProgress /> }
        { !loadingDatas  &&
            <Grid container spacing={2} alignItems={'start'} marginTop={1}>                 
                <Grid item xs={12} sm={6}>
                    <Typography component="p">
                        {   intl.formatMessage(
                            { id: "backoffice.repositories.creation.list.title" }, 
                            { values:  selectedReferenceTable.label})
                        }
                    </Typography>
                    <CvcListVirtualized 
                        items={referenceDataList} 
                        showFilter={true}
                        filterLabel={intl.formatMessage({ id: "backoffice.repositories.creation.list.filter.label" })}
                        showReloadData={true} 
                        onReloadData={handleReloadData} 
                        listHeight={450}              
                    />
                </Grid>                
                <Grid item xs={12} sm={6}>
                    <Grid container direction='row' rowGap={2}>
                       <Grid item xs={12} alignContent={'center'}>
                            <Typography component="p">
                                {intl.formatMessage({ id: "backoffice.repositories.creation.textfield.title" })}
                            </Typography>
                        </Grid> 
                          
                        <Grid item xs={12} sm={6}>                    
                            <RhfTextField
                                label="backoffice.repositories.creation.textfield.label"
                                name="valueToAdd"
                                required={true}
                                sx={{ width: "90%" }}
                                defaultFocus={true}
                                onChange= {handleValueToAddChange}
                                control={useFormMethods.control}
                                error={useFormMethods.formState.errors ? useFormMethods.formState.errors.valueToAdd : null}
                            />
                        </Grid> 

                        { (isUniversityDomain || isUniversityUnit) &&                   
                            <Grid item xs={12} sm={6}> 
                                <RhfListOfValuesSelect
                                    name="universityId"
                                    label={"academic.university"}
                                    required={true}
                                    disabled={disabledUniversity}
                                    group={GROUP.university}
                                    sx={{ width: "90%" }}
                                    control={useFormMethods.control}
                                    error={useFormMethods.formState.errors ? useFormMethods.formState.errors.universityId : null}
                                />
                            </Grid>     
                        }

                        <Grid item xs={12} sm={6}>
                            <Button variant='contained' disabled={disabledAddBtn} onClick={handleClickAddNewValue}>
                                {intl.formatMessage({ id: "backoffice.repositories.creation.btn.add" })}
                            </Button>
                            <CvcConfirmActionDialog 
                                open={openConfirmDialog} 
                                title={intl.formatMessage({id: "backoffice.repositories.creation.dialog.confirmAdd.title"})} 
                                contentText={intl.formatMessage({id: "backoffice.repositories.creation.dialog.confirmAdd.contentText"}, {valueToAdd: useFormMethods.getValues("valueToAdd")})} 
                                onClose={handleCloseConfirmDialog} 
                                cancelBtnLabel={intl.formatMessage({id: "backoffice.repositories.creation.dialog.confirmAdd.btn.cancel"})} 
                                onCancel={handleCloseConfirmDialog} 
                                actionBtnLabel={intl.formatMessage({id: "backoffice.repositories.creation.dialog.confirmAdd.btn.add"})} 
                                onAction={handleConfirmAction}  
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        } 
    </>);
}


export default AddReferenceDataTab;
