import React, { useCallback, useEffect, useRef, useState } from "react";

import { Accordion, AccordionSummary, AccordionDetails, AccordionActions, 
        Button, Card, CardContent, Divider, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {useForm} from "react-hook-form";
import {useIntl} from "gatsby-plugin-intl";

import Seo from "components/common/Seo";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import CustomDataGrid from "components/backoffice/common/CustomDataGrid";
import academicService from "services/api/academicService";
import { RhfListOfValuesSelect, RhfRadioButtonAllRow, RhfTextField,} from "components/backoffice/common/RhfFields";
import RhfAutoCompleteListOfValuesMultiChoice from "ui/RhfAutoCompleteListOfValuesMultiChoice";
import RhfListOfValues from "ui/RhfListOfValues";
import { GROUP } from "utils/constants/back-office-constants";
import { containsRole, getUniversityId } from "services/auth";
import { ROLE } from "utils/constants/constants";
import { downloadCSV, convertToCSV } from "utils/export-utils";



const  LOCAL_STORAGE_SEARCH_CRITERIA = "backoffice.academics.searchCriteria";

const defaultStyles = {
    card: {
        maxHeight: '100%',
        backgroundColor: "white"
    },
    cardContent: {
        backgroundColor: "white"
    },
    filter: {
        title: {
            fontSize: "1rem",
            fontWeight: 400,
            color: "inherit",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            lineHeight: 1.5
        }
    }
};

const AcademicListPage = () => {
    const intl = useIntl();
    const columns = [
        {
            flex: 1,
            //minWidth: 200,
            field: "identity",
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({id: "academic.lastName"}),
            renderCell: (field) => {
                return field.value;
            }
        },
        {
            flex: 1,
            //maxWidth: 400,
            field: "universityName",
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({id: "academics.filter.university"}),
            renderCell: (field) => {
                return field.value;
            }
        },
        {
            flex: 1,
            //minWidth: 400,
            field: "universityUnitName",
            sortable: false,
            filterable: false,
            headerName:  intl.formatMessage({id: "academics.filter.researchCenter"}),
            renderCell: (field) => {
                return field.value;
            }
        },
        {
            flex: 1,
            //maxWidth: 70,
            field: "visible",
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({id: "academic.visible"}),
            renderCell: (field) => {
                 return ''+field.value;
            }
        }
    ];
    const editColumnParams = {
        field: "academicId",
        arialLabel: "modifyAcademic",
        url: "/back-office/faculty/edit"
    }

    const defaultFilterValues = {
        textFilter: "",
        expertises: [],
        academicsUniversity: "",
        academicUnit: "",
        visible: ""
    }

    const useFormMethods = useForm({
        defaultValues: defaultFilterValues
    });

    const pageSize = 50;
    const [totalNumberOfRow, setTotalNumberOfRow] = useState(0);
    const searchCriteria = useRef({});

    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectionModel, setSelectionModle] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [disabledExport, setDisabledExport] = useState(false);
    const [page, setPage] = useState(0);
    const [unitList, setUnitList] = useState([]);

    const submitForm = (formData) => {
        searchCriteria.current = formData;
        localStorage.setItem(LOCAL_STORAGE_SEARCH_CRITERIA, JSON.stringify(searchCriteria.current));
        loadServerRows(0, []);
    };

    const resetForm = () => {
        useFormMethods.reset(defaultFilterValues);
        searchCriteria.current = useFormMethods.getValues();
        localStorage.removeItem(LOCAL_STORAGE_SEARCH_CRITERIA);
        loadServerRows(0, []);
    };

    const loadServerRows = useCallback(async (page, sortModel) => {
        setDisabledSubmit(true);
        setDisabledExport(true);
        setLoading(true);
        academicService.searchAcademicsByCriteria(
            searchCriteria.current,
            page,
            pageSize,
            sortModel
        ).then((response) => {
            let academics = [];
            if(response.data.content){
                academics = response.data.content.map(row => (
                    { ...row, 
                        identity: row.lastName +' ' + row.firstName,
                        id: row.academicId,
                        deletable: getUniversityId() == row.universityId || containsRole([ROLE.admin]),
                        editable: getUniversityId() == row.universityId || containsRole([ROLE.admin])
                    }
                ));
            }
            setPage(page);
            setTotalNumberOfRow(response.data.totalSize);
            setRows(academics);
            setLoading(false);
            setDisabledSubmit(false);
            setDisabledExport(false);

        })
        .catch((error) => {
            console.error("error searching academics by criteria", error);
            setLoading(false);
            setDisabledSubmit(false);
            setDisabledExport(false);

        });

    }, []);

    const handleDelete = async (selection) => {
        setDisabledSubmit(true);
        setDisabledExport(true);
        setLoading(true);
        academicService.deleteAcademics(selection)
        .then(() => {
            setDisabledSubmit(false);
            setDisabledExport(false);
            setLoading(false);
            setRows(
                rows.filter((r) => selection.filter((sr) => sr === r.id).length < 1)
            );
            setSelectionModle([]);
        })
        .catch((error) => {
            console.error("error deleting academics", error);
            setLoading(false);
            setDisabledSubmit(false);
            setDisabledExport(false);

        });
    };

    const showExportButton = containsRole([ROLE.admin, ROLE.admin_university]);

    const handleExport = async () => {
        setDisabledSubmit(true);
        setDisabledExport(true);
        setLoading(true);        
        try {
            const response = await academicService.exportAcademicsByCriteria(searchCriteria.current)        
            const data = await convertToCSV(response.data);
            await downloadCSV(data, 'academicList');
            setLoading(false);
            setDisabledSubmit(false);
            setDisabledExport(false);
        } catch(error) {
            console.error("error exporting academics", error);
            setLoading(false);
            setDisabledSubmit(false);
            setDisabledExport(false);
        };
    };

    const handleSelectionChange = (newSelectionModel) => {
        setSelectionModle(newSelectionModel);
    };

    useEffect(() => {
        let item = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SEARCH_CRITERIA));
        if(item){
            searchCriteria.current = item;
            Object.keys(item).forEach(key => {
                useFormMethods.setValue(key,item[key])
            });
        }
        loadServerRows(0, []);
        setRows([]);
    }, [loadServerRows]);

    const university = useFormMethods.watch("academicsUniversity");
    
    useEffect(() => {
        useFormMethods.setValue("academicUnit", "");
        if(university != '') {
            updateUniversityUnitList(university);
        } 
    }, 
    [university]);

    const updateUniversityUnitList = async (universityId) => {
        await academicService.findUnitsByUniversityId(universityId)
            .then(response => {
                const univUnitList = response.data;
                if (univUnitList) {
                    setUnitList(univUnitList);
                } else {
                    console.error(`no units found for university id ${universityId} `);
                }
            }).catch(error => {
                console.error("error fetching university's units", error);
            });
    }

    return (
        <>
            <LayoutBo>
                
                <Seo lang={intl.locale} title={intl.formatMessage({id: "academic.list.title"})}/>

                <Card>
                    <CardContent>
                        
                        <form onSubmit={useFormMethods.handleSubmit(submitForm)} noValidate>   
                            <Accordion defaultExpanded>
                                <AccordionSummary  id="filter-title" expandIcon={<ExpandMoreIcon/>}>
                                    <h1 style={ defaultStyles.filter.title }>
                                        {intl.formatMessage({id: "backoffice.filter.title"})}
                                    </h1>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Grid item container spacing={2}>

                                        <Grid item xs={12} md={12}>
                                            <RhfTextField id="filter-textFilter"
                                                name="textFilter"
                                                label={"backoffice.filter.fullText.label"}
                                                helpMessage={intl.formatMessage({
                                                    id: "backoffice.academics.filter.fullText.helpMessage",
                                                })}
                                                control={useFormMethods.control}                                                        
                                                fullWidth
                                                sx={{ width: "100%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <RhfAutoCompleteListOfValuesMultiChoice 
                                                id="filter-expertises"
                                                name={"expertises"}
                                                inputLabel={"academic.expertiseFields"}
                                                group={GROUP.expertiseField}
                                                control={useFormMethods.control}
                                                setValue={useFormMethods.setValue}                                                                                             
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <RhfListOfValuesSelect 
                                                id="filter-university"
                                                name="academicsUniversity"
                                                label={"academics.filter.university"}
                                                group={GROUP.university}
                                                control={useFormMethods.control}
                                                sx={{ width: "100%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <RhfListOfValues 
                                                id="filter-unit"
                                                name="academicUnit"
                                                label={"academics.filter.researchCenter"}
                                                items={unitList}
                                                group={GROUP.universityUnit}
                                                control={useFormMethods.control}
                                                sx={{ width: "100%" }} 
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <RhfRadioButtonAllRow 
                                                id="filter-visible"
                                                name={"visible"}
                                                control={useFormMethods.control}
                                                label={"academics.filter.visible.label"} 
                                                //defaultValue={undefined}                                            
                                            />
                                        </Grid>

                                    </Grid>
                                </AccordionDetails>
                                <Divider/>

                                <AccordionActions style={{ justifyContent: 'center'}}>
                                    <Button color="secondary" onClick={resetForm}>
                                    {intl.formatMessage({id: "course.filter.reset"})}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={disabledSubmit}
                                    >
                                    {intl.formatMessage({id: "course.filter.search"})}
                                    </Button>
                                </AccordionActions>

                            </Accordion>
                        </form>

                        <CustomDataGrid
                            columns={columns}
                            editColumnParams={editColumnParams}
                            onDelete={handleDelete}
                            loadServerRows={loadServerRows}
                            pageSize={pageSize}
                            totalRows={totalNumberOfRow}
                            changeSelection={handleSelectionChange}
                            selectionModel={selectionModel}
                            rows={rows}
                            addMessage="academics.add"
                            loading={loading}
                            addLink="/back-office/faculty/add"
                            rowDoubleClickLink="/back-office/faculty/edit"
                            page={page}

                            // Bouton Export de la PhD Clinic
                            showExportButton={showExportButton}
                            onExport={handleExport}
                            disabledExport={disabledExport}
                        />

                    </CardContent>
                </Card>

            </LayoutBo>
        </>
    );
};

export default AcademicListPage;

