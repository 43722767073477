import * as React from 'react';

import { useIntl } from 'gatsby-plugin-intl';
import { Controller } from "react-hook-form";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const defaultSx = { width: 300 };

const defaultGetOptionKey = (option) => {
  return option.value;
}

const defaultGetOptionLabel = (option) => {
  const hasLabel = option.hasOwnProperty('label');
  return hasLabel ? option.label : '';
};

const CvcAutocompleteSelect = ({ 
    id = 'CvcAutocompleteSelect',  
    label, 
    options,     
    placeholder = "",    
    value = "", 
    onOptionChange = null, 
    required = false, 
    disabled = false,
    loading = false, 
    sx = defaultSx, 
    getOptionLabel = defaultGetOptionLabel,     
    getOptionKey = defaultGetOptionKey,     
    error = null,   
    onBlur = null    
}) =>
{
    const intl = useIntl();
    const isOptionEqualToValue = (option, value) => {
      return getOptionKey(option) === getOptionKey(value);
    }; 

    return (
        <Autocomplete  
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys

            id={id} 
            sx={sx}                                         
            disabled={disabled}
            loading={loading}
            options={options}

            value={value}
            onChange={(event, newValue) => {
                onOptionChange(newValue);
            }}            
            onBlur={onBlur}

            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={(option) => {
                return getOptionLabel(option);
            }}
            getOptionKey={(option) => {
              return getOptionKey(option);
          }}
            
            renderOption={(props, option) => {
              return (
                <li key={getOptionKey(option)} {...props}>
                  {getOptionLabel(option)}
                </li>
              );
            }}

            renderInput={(params) => (
                <TextField {...params} 
                  variant='standard'                   
                  label={label} 
                  placeholder={placeholder}
                  required={required}  
                  error={!!error}
                  helperText={ error ? intl.formatMessage({id: error.message}) : null }          
                />
            )}            
        />
    );
}

const RhfAutocompleteSelect = ({ 
  id = 'RhfAutocompleteSelect', 
  name, 
  control,
  label, 
  options = [], 
  placeholder = "", 
  onOptionChange = null, 
  required = false,
  disabled = false,
  loading = false,       
  sx = defaultSx,
  getOptionLabel = defaultGetOptionLabel,     
  getOptionKey = defaultGetOptionKey,    
  error = null,   
  onBlur = null   
}) => {

  return (
      <Controller
        name={name}
        control={control} 
        render={({ field: { onChange, value } }) => (
          <>
            <CvcAutocompleteSelect
                id={id}
                label={label}
                placeholder={placeholder} 
                sx={sx}
                required={required}
                disabled={disabled}
                loading={loading}
                options={options}
                value={value}                
                onBlur={onBlur}
                onOptionChange={(selectedOption) => {
                  onOptionChange(selectedOption);
                  onChange(selectedOption);
                }}
                getOptionLabel={getOptionLabel}
                getOptionKey={getOptionKey}
                error={error}                   
            /> 
          </>
        )}
      />
  );
}


export {
  CvcAutocompleteSelect,
  RhfAutocompleteSelect
}

