import React from "react";

import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useIntl } from "gatsby-plugin-intl";
import CvcTransferList from "ui/CvcTransferList";

const StandardizeSelectDataPanel = ({ 
    selectedReferenceTable,
    referenceDataList, 
    selectedData, 
    onChange, 
    onReloadData, 
    onStandardize, 
    disabledStandardizeBtn 
}) => {

    const intl = useIntl();
    const showActions = {
        showReloadData: true,
        showMoveAllRight: false,
        showMoveAllLeft: true,
        showMoveSelectedRight: true,
        showMoveSelectedLeft: true
    }
    return (
        <Grid container alignItems='center' rowSpacing={2} sx={{ width: '100%', height: '100%' }}>                
            <Grid item xs={12} sm={10}>

                <Grid container marginTop={'20px'} columnSpacing={10}>
                    <Grid item xs={6}>
                        <Typography component="p">
                            {   intl.formatMessage(
                                { id: "backoffice.repositories.standardisation.transferlist.left.title" }, 
                                { values:  selectedReferenceTable.label})
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component="p">
                            {   intl.formatMessage(
                                { id: "backoffice.repositories.standardisation.transferlist.right.title" }, 
                                { values:  selectedReferenceTable.label})
                            }
                        </Typography>
                    </Grid>
                </Grid> 

                <CvcTransferList 
                    filterLabel={intl.formatMessage({ id: "backoffice.repositories.standardisation.transferlist.filter.label" })}
                    showActions={showActions}
                    items={referenceDataList}
                    selectedItems={selectedData}
                    onChange={onChange}
                    showReloadData={true}
                    onReloadData={onReloadData}                
                    listHeight={440}
                />
            </Grid>

            <Grid item xs={12} sm={2}>
                <Button variant='contained' disabled={disabledStandardizeBtn} onClick={onStandardize}>
                    {intl.formatMessage({ id: "backoffice.repositories.standardisation.btn.standardise" })}
                </Button>
            </Grid>
        </Grid>
    )
}

export default StandardizeSelectDataPanel;