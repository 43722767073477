import { FormControlLabel, Grid, Stack, Switch, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIntl } from "gatsby-plugin-intl";
import userService from "services/api/userService";
import ResultSnackbars from "../../common/ResultSnackbars";
import { capitalizeFirstLetter } from "utils/string-utils";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { containsRole } from "services/auth";
import { ROLE } from "utils/constants/constants";


const EditUserRolesTab = ({ userId = null }) => {
    const intl = useIntl();
    const styles = {
        gridContainer: {
            rowSpacing: 1,
            columnSpacing: 1,
            style: {
                width: "100%", 
                height: "100%",
                margin: "0px 0px 0px",
                padding: "0px 0px 0px"
            }    
        },
        typoUserName: {
            color: "grey",
            fontWeight: 400
        },
        typoTitle: {
            color: "grey",
            fontWeight: 400
        },
        gridItem: {
            rowSpacing: 0,
            columnSpacing: 0,
            style: {
                //border: "1px solid green",
                width: "100%", 
                height: "100%",
                margin: "0px 0px 0px",
                padding: "0px 8px 0px",
            }
        },

    }

    const [user, setUser] = useState({
        userId: null,
        firstName: "",
        lastName: "",
        universityId: null,
        universityAcronym: "",
        universityName: "",
        roles: []
      });
    
    const [resultMessage, setResultMessage] = useState("");
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);

    useEffect(() => {
      let mounted = true;
      const fetchUserRoles = async () => {
          try {
              const results = await userService.findUserRolesByUserId(userId);
              const user = results.data;
              if(mounted) {
                setUser(user);
              }
          } catch (error) {
              console.error("error fetching user's roles", error);
              //navigate("/back-office/users");
          }
      };
      if (!!userId) {
        fetchUserRoles();
      }
      return () => { mounted = false };
    }, []);


    const handleChangeRole = async (event) => {
        const roleId = event?.target?.name;
        const newRoleStatus = event?.target?.checked ? "ON" : "OFF";
        changeUserRole(roleId, newRoleStatus);
        changeRolesState(roleId, newRoleStatus);
    }

    const changeUserRole = async (roleId, newRoleStatus) => {
        setOpenSuccess(false);
        setOpenError(false);
        const userRoleInfos = {
            universityId: user?.universityId,
            roleId: roleId,
            roleStatus: newRoleStatus,
            userId: userId
        };
        userService
            .modifyUserRole(userId, userRoleInfos)
            .then((result) => {
                setResultMessage("backoffice.users.edit.roles.success");
                setOpenSuccess(true);
            })
            .catch((error) => {
                handleError(error);
            });
    };

    function changeRolesState(roleId, newRoleStatus) {
        const nextRoles = user.roles.map(role => {
            if (role.id === roleId) {
                const newRole =  {
                    ...role,
                    state: newRoleStatus
                };
                return newRole;
            } else {
                return role;
            }
        });
        const nextUser = {
            ...user,
            roles: nextRoles
        }
        setUser(nextUser);
    }

    const handleError = (error) => {
        let errLabel = "backoffice.users.edit.roles.error";
        if (error && error.data && error.data.errorCode) {
            errLabel = error.data.errorCode;
        }
        console.error(intl.formatMessage({id: errLabel}), error);
        setResultMessage(errLabel);
        setOpenError(true);
    }

    const getTooltipTitle = (role) => {
        switch(role.name){
            case 'ADMIN_UNIVERSITY':
            case 'ACADEMICS_MANAGER':
            case 'COURSES_MANAGER':
            case 'COURSES_APPROVER':
            case 'NEWS_MANAGER':
            case 'ACADEMIC':
            case 'TEMP':
                return intl.formatMessage({id: `backoffice.users.edit.roles.tooltip.${role.name}`});    
            default: 
                return '';    
        }
    }

    return (
    <>

        <ResultSnackbars
            openError={openError}
            openSuccess={openSuccess}
            messageId={resultMessage}
            setOpenSuccess={setOpenSuccess}
            setOpenError={setOpenError}
        />  

        {/* <pre>{JSON.stringify(user, null, 2)}</pre>  */}

        <Grid container 
            columnSpacing={ styles.gridContainer.columnSpacing }
            rowSpacing={ styles.gridContainer.rowSpacing } 
            style={ styles.gridContainer.style }
        >
            <Grid item xs={12} md={12} textAlign={{ xs: "left", md: "left" }}>
                <Typography sx={styles.typoUserName}>
                    { capitalizeFirstLetter(user?.firstName) + ' ' + user?.lastName?.toUpperCase() 
                    + ' ( ' +  user?.universityAcronym+ ' - ' + user?.universityName +  ' )'
                    }
                </Typography>
            </Grid>
            <Grid item xs={12} md={12} textAlign={{ xs: "left", md: "left" }}>
                <Typography sx={ styles.typoTitle }>
                    { intl.formatMessage({id: "backoffice.users.edit.roles.title"})}
                </Typography>
            </Grid>
            {               
                user.roles.map( (role, index) => 

                    <Grid item key={index} xs={12} md={12} style={ styles.gridItem.style } textAlign={{ xs: "left", md: "left" }}> 
                        <Tooltip title={getTooltipTitle(role)} placement="left-end" arrow={true}>
                            <FormControlLabel 
                                label={role.group + ' - ' + role.name}
                                control={
                                    <Switch checked={role.state == 'ON' ? true : false } onChange={handleChangeRole} name={role.id} />
                                }
                                disabled={ role.name == 'ADMIN' && !containsRole([ROLE.admin]) }
                            />
                        </Tooltip>
                    </Grid> 
                )

            }
           
        </Grid>

        <Stack direction="row" spacing={5} margin={5} justifyContent={"center"}>
            <ConfirmationDialog listingUrl={`/back-office/users`} />
        </Stack> 
    
    </>
    )
}

export default EditUserRolesTab;